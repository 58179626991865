import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Written by Kyle Rubenok. I'm a PM at Microsoft. Previously McGill CS, President at `}<a parentName="p" {...{
        "href": "https://mcgill-csus.ca"
      }}>{`McGill CSUS`}</a>{`, Co-Director at `}<a parentName="p" {...{
        "href": "https://hackmcgill.ca"
      }}>{`HackMcGill`}</a>{` and Founding Partner at `}<a parentName="p" {...{
        "href": "https://www.penguinleaf.com"
      }}>{`Penguinleaf`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      